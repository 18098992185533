import React from "react";
import { Link } from "gatsby";
// import cx from "classnames";

const Footer = ({ className, ...props }) => {
  // className = cx(
  //   "w-full px-8 py-4 text-white bg-gray-800 flex flex-col md:flex-row justify-between items-start md:items-center",
  //   className
  // );
  return (
    <>
      {/* <div className="w-full px-8 py-4 text-white bg-gray-800 flex flex-col md:flex-row justify-between md:items-center">
        <div className="flex-initial text-lg font-semibold text-center">+49 176 47 68 44 77</div>
        <div> <p className="flex flex-col md:flex-row text-lg -mx-3 font-medium text-center">  contact@simpleheroes.de</p></div>
      </div> */}

      <div className="w-full pin-b px-2 text-white text-xs font-light bg-gray-900 flex flex-col sm:flex-row justify-between text-center ms:items-center md:items-center sticky bottom-0">
        {/* <div>
          <p>+49 176 47 68 44 77  contact@simpleheroes.de</p>
        </div> */}
         <div className="w-full px-4 py-2 text-white flex flex-col md:flex-row justify-start md:items-center">
           <Link to="/imprint" className="hover:underline text-center text-white flex flex-col md:flex-row text-xs mx-2">Impressum</Link>
           <Link to="/privacy" className="hover:underline text-center text-white flex flex-col md:flex-row text-xs">Datenschutz</Link>

        </div>
        <div className="w-full px-4 py-2 text-white flex flex-col md:flex-row justify-end md:items-center">
          <Link to="/" className="hover:underline text-center text-white">© 2020 simpleheroes</Link>
        </div>
       
      </div>
    </>
  );
};

export default Footer;